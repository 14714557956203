<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button type="success" @click="onPressNewProdSupplier('supplierAdminData')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Шинэ нийлүүлэгч үүсгэх'"/>
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="supplierAdminData" ref="supplierAdminData">
          <el-row :gutter="20">
            <el-col :span="20">
              <div class="panel">
              <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Нэр" prop="Username">
                        <el-input v-model="supplierAdminData.Username" disabled placeholder="Говь ХХК ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Майл" prop="email">
                      <el-input v-model="supplierAdminData.email" disabled placeholder="Говь ХХК ..."></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="Үүссэн огноо">
                          <el-input placeholder="Дижитал аггрегатор" disabled type="text" :value='supplierAdminData.UserCreateDate'></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                      <el-form-item label="Сүүлд өөрчилсөн" prop="phone">
                          <el-input v-model="supplierAdminData.UserLastModifiedDate" disabled placeholder="99999999"></el-input>
                        </el-form-item >
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="Удирдах нийлүүлэгчид" prop="supplierIds">
                          <el-select v-model="supplierAdminData.supplierIds" multiple filterable placeholder="Select" style="width: 98%">
                            <el-option
                              v-for="(item, index) in suppliers"
                              :key="index"
                              :label="item.supplier_monName"
                              :value="item.supplier_id">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Нууц үг">
                          <el-button size="medium" type="info" @click="changePasswordDialog = true" plain>Нууц үг солих</el-button>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="Хамаарагдах харилцагч харах">
                          <el-popover
                            placement="top"
                            width="800">
                            <div class="container" v-if="userData && Object.prototype.hasOwnProperty.call(userData, 'employees') && userData.employees.length > 0">
                              <el-row type="flex">
                                <div class="card" v-for="(item, index) in userData.employees" :key="index">
                              <div class="content">
                                <el-col :span="7" :offset="1">
                                <div class="avatar">
                                  <img src="../../../assets/ss.png" alt="avatar">
                                </div>
                                </el-col>
                                <el-col :span="16">
                                <div class="details">
                                  <div>
                                    <h3>{{ item.name }}</h3>
                                  </div>
                                  <div>
                                    <i class="el-icon-phone"></i>
                                    <span>{{ item.phone }}</span>
                                  </div>
                                  <div>
                                    <i class="el-icon-s-cooperation"></i>
                                    <span>{{ item.major }}</span>
                                  </div>
                                  <div>
                                    <i class="el-icon-paperclip"></i>
                                    <span>{{ item.email }}</span>
                                  </div>
                                  <div>
                                    <i class="el-icon-location"></i>
                                    <span>{{ item.address }}</span>
                                  </div>
                                </div>
                                </el-col>
                              </div>
                            </div>
                            </el-row>
                            </div>
                            <p class="emptyClient" v-else>Хамрагдах харилцагч байхгүй байна. 😑</p>
                            <el-button size="medium" type="primary" slot="reference">
                              <i class="el-icon-view"> Харах</i>
                            </el-button>
                            </el-popover>
                        </el-form-item>
                      </el-col>
                    </el-row>
                </div>
                </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="5">
              </el-col>
              <el-col :span="20" class="text-right">
                <el-button type="danger" @click="deleteAccount = true" style="float:left">Бүртгэл устгах</el-button>
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="onPressSupplierUpdate()
                ">Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Change password dialog -->
    <el-dialog
      title="Нууц үг солих"
      :visible.sync="changePasswordDialog"
      width="30%">
      <p>Шинэ нууц үгээ оруулна уу!</p>
      <el-popover
    placement="top-start"
    title="Анхаар!!!"
    width="180"
    trigger="hover"
    content="Нууц үг хамгийн багадаа 8 оронтой байх ёстой!">
        <el-input slot="reference" placeholder="Шинэ нууц үг" v-model="changePassword" show-password></el-input>
  </el-popover>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changePasswordDialog = false">Болих</el-button>
        <el-button type="success" @click="changePass" :disabled="changePassword.length < 8 ? true : false">Хадгалах</el-button>
      </span>
    </el-dialog>
    <!-- Delete account dialog-->
    <el-dialog
      title="Бүртгэл устгах"
      :visible.sync="deleteAccount"
      width="30%">
      <span>{{supplierAdminData.Username }} Нэртэй account-ийг устгахын тулд delete гэж бичнэ үү!</span>
      <el-input placeholder="delete гэж бичнэ үү!" v-model="deleteWrite"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteAccount = false">Болих</el-button>
        <el-button type="danger" @click="removeUser" :disabled="deleteWrite === 'delete' ? false : true">Устгах</el-button>
      </span>
    </el-dialog>

    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/supplier-admin-list">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import customHeader from '../../../components/common/customHeader'
import services from '../../../helpers/services'
import { getUser } from '../../../utils/auth'
export default {
  components: {
    customHeader: customHeader
  },

  data () {
    return {
      changePasswordDialog: false,
      deleteWrite: '',
      changePassword: '',
      deleteAccount: false,
      userName: '',
      userData: [],
      supplierLoading: false,
      suppliers: [],
      loading: false,
      confirmDeleteSupplierDialog: false,
      confirmLeaveForm: false,
      brandList: [],
      supplierBrand: [],
      errorArray: [],
      pageStatus: {
        error: false,
        message: ''
      },
      supplierAdminData: {
        supplierIds: []
      }
    }
  },

  created () {
    if (this.$route.params.user_name) {
      this.getOneSupplierAdmin(this.$route.params.user_name)
      this.getSupplier()
      this.getUserClient(this.$route.params.user_name)
    }
  },
  mounted () {
    getUser().then((user) => {
      this.userName = user.username
    })
  },
  methods: {
    changePass () {
      const body = {
        userName: this.supplierAdminData.Username,
        password: this.changePassword
      }
      const query = '?user_name=' + this.supplierAdminData.Username
      services.changePasswordSupplier(query, body).then(el => {
        if (el.status === 'success') {
          this.alertReporter('Амжилттай', 'Нууц үг амжилттай солигдлоо', 'success')
          this.changePassword = ''
          this.changePasswordDialog = false
        } else {
          this.alertReporter('Алдаа', el.message, 'error')
        }
      })
    },
    removeUser () {
      const query = '?user_name=' + this.supplierAdminData.Username
      services.removeUser(query).then(el => {
        if (el.status === 'success') {
          this.alertReporter('Амжилттай', 'Амжилттай устгалаа', 'success')
          this.deleteAccount = false
        } else {
          this.alertReporter('Алдаа', el.message, 'error')
        }
      })
    },
    confirmSignUp (user) {
      services.confirmSignUp({ user_name: user }).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', 'Амжилттай баталгаажсан.', 'success')
          this.getOneSupplierAdmin(this.$route.params.user_name)
        } else {
          this.alertReporter('Амжилттай', response.message, 'error')
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    },

    onPressSupplierUpdate () {
      services.updateSupplierAdmins({ userName: this.supplierAdminData.Username, supplierIds: this.supplierAdminData.supplierIds }).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', response.message, 'success')
          this.supplierAdminData = response.data
          this.$router.push({ name: 'supplierAdminList' })
        } else if (response.status === 'error') {
          this.alertReporter('Амжилтгүй', response.message, 'error')
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    },

    getSupplier () {
      this.supplierLoading = true
      services.getSuppliers('?size=2000').then(response => {
        if (response.datas && Array.isArray(response.datas)) {
          this.suppliers = response.datas
          this.supplierLoading = false
        } else {
          this.suppliers = []
          this.supplierLoading = false
        }
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    getOneSupplierAdmin (userName) {
      services.getSupplierAdmin(userName).then(response => {
        if (response.status === 'success') {
          this.supplierAdminData = response.data
          this.supplierAdminData.supplierIds = JSON.parse(response.data.supplierIds)
          this.loading = false
        } else if (response.status === 'error') {
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    },
    getUserClient (userName) {
      const query = '?user_name=' + userName
      services.getUser(query).then(response => {
        if (response.status === 'success') {
          this.userData = response.data
        }
      })
    }
  }

}
</script>

<style scoped>
.emptyClient{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.container {
    margin-right: 20px;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 300px;
    display: flex;
    overflow-x: auto;
}

.card {
    margin-left: 30px;
    padding-right: 20px;
    position: relative;
    width: 200px;
    height: 200px;
    background: #fff;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 25px;
    box-shadow: 0 25px 25px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    transition: 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

}

.card:hover {
    transform: scale(1.1);
    background: rgb(255, 255, 255);
    z-index: 9999999;
}

.card::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
}

.card .content {
    font-size: 12px;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -20px;
    gap: 10px;
}

.card .content .avatar {
    margin-top: -80px;
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
}

.card .content .avatar img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card .content .details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #111;
}

.card .content .details div {
    display:  flex;
    align-items: center;
    gap: 10px;
}

.card .content .details ion-icon {
    font-size: 1.5em;
}
</style>
